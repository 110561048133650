import { computed, watch, reactive, toRefs, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { CommonFunction } from '@/common/CommonFunction'
import { niceForm } from '@/common/form/NiceForm'

const INIT_PAGE = 0
const INIT_LIMIT = 20
const INIT_TOTAL_CNT = 0

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const { getters, dispatch } = useStore()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    rowType: 1,
    items: [],
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    searchText: route.query.search || '',
    totalCnt: INIT_TOTAL_CNT,
    list: [],
    initCnt: 0,
    sort: 'MODDT_ASC',
    params: computed({
      get: () => {
        return {
          startNum: state.list.length + 1,
          endNum: (state.page + 1) * state.limit,
          searchText: state.searchText,
          categoryId: '',
          sort: state.sort,
          endYn: 'Y'
        }
      }
    }),
    timer: undefined,
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/my-info/success`,
    failRtnUrl: `${window.location.origin}/my-info/fail`
  })
  const { fnWrite, fnUpdateAuthType } = CommonFunction(state)
  const fnShowOn = async index => {
    await dispatch('nav/updateOnDepth2Item', {
      onDepth2Item: state.items[index]
    })
    state.items[index].isShow = true
    state.items.forEach((mItem, mIndex) => {
      if (index !== mIndex) {
        mItem.isShow = false
      }
    })
    fnSearch()
  }
  const fnListFunction = async () => {
    const res = await proxy.$PartySvc.postPartyList(state.params)
    if (res.resultCode === '0000') {
      state.page++
      state.totalCnt = res.totalCnt
      state.list = state.list.concat(res.list)
    } else if (res.resultCode === '0001') {
      // 데이터 없는경우
      fnReset()
    } else {
      alert(res.resultMsg)
    }
  }
  const fnSearch = () => {
    fnReset()
    state.initCnt++
  }
  const fnReset = () => {
    state.page = INIT_PAGE
    state.limit = INIT_LIMIT
    state.totalCnt = INIT_TOTAL_CNT
    state.list = []
  }
  const fnCallback = async params => {
    if (!proxy.$Util.isEmpty(params)) {
      if (
        params.callType === proxy.$ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
      ) {
        fnUpdateAuthType()
      }
      await router.push({ path: '/my-info/phone-auth' })
      // await fnMyInfo()
    } else {
      alert('본인인증에 실패하였습니다.')
    }
  }
  const fnToggleView = (type = 1) => {
    state.rowType = type
  }
  const init = async () => {
    await dispatch('nav/updateOnDepth1Item', {
      onDepth1Item: {}
    })
    await dispatch('nav/updateOnDepth2Item', {
      onDepth2Item: {}
    })
  }
  /** watch **/
  watch(
    () => state.onDepth1Item,
    () => {
      const all = {
        categoryID: '',
        categoryName: 'ALL',
        categoryOrder: '-1',
        depth: 2,
        upperCategoryID: '0000',
        url: '',
        isShow: true
      }
      state.items = [all]
      if (
        !proxy.$Util.isEmpty(state.onDepth1Item) &&
        !proxy.$Util.isEmpty(state.onDepth1Item.categoryList)
      ) {
        const temp = state.onDepth1Item.categoryList.map(item => {
          return {
            ...item,
            isShow: false
          }
        })
        state.items = state.items.concat([...temp])
      }
      fnSearch()
    },
    { immediate: true }
  )
  watch(
    () => state.sort,
    () => {
      fnReset()
      state.initCnt++
    }
  )
  init()
  return {
    ...toRefs(state),
    fnShowOn,
    fnListFunction,
    fnSearch,
    fnWrite,
    fnCallback,
    fnToggleView
  }
}
